<template>
  <div class="subscriber-create-way">
    <div class="subscriber-create-way-background" @click="$router.push('/subscribers')" />
    <div class="subscriber-create-way-container">
      <div class="subscriber-create-way-close" @click="$router.push('/subscribers')">
        <inline-svg :src="require('@/assets/icons/close.svg')" />
      </div>
      <div class="subscriber-create-way-left">
        <div class="subscriber-create-way-left-title">Create a subscriber</div>
        <div class="subscriber-create-way-left-description">
          You can manually create a subscriber record. The subscriber will be notified by SMS to
          complete the registration process including adding their credit card and agreeing to the
          billing terms.
        </div>
      </div>
      <div class="subscriber-create-way-right">
        <div class="subscriber-create-way-right-item">
          <img src="@/assets/csv.png" />
          <div
            class="subscriber-create-way-button"
            @click="$router.push('/subscribers/bulk-upload')"
          >
            Upload via XLSX
          </div>
        </div>
        <div class="subscriber-create-way-right-item">
          <img src="@/assets/manually.png" />
          <div class="subscriber-create-way-button" @click="$router.push('/subscribers/create')">
            Create manually
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "SubscriberCreateWay",
  components: {
    InlineSvg,
  },
};
</script>

<style>
.subscriber-create-way {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 135px 0;
}

.subscriber-create-way-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d33;
  opacity: 0.8;
}

.subscriber-create-way-close {
  position: absolute;
  top: -64px;
  cursor: pointer;
  right: 0;
}

.subscriber-create-way-container {
  z-index: 1;
  position: relative;
  width: 1110px;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
}

.subscriber-create-way-left {
  background: #0ba3a9;
  padding: 32px 27px;
  flex: 0 0 445px;
}

.subscriber-create-way-left-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #ffffff;
  margin-bottom: 68px;
}

.subscriber-create-way-left-description {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #ffffff;
}

.subscriber-create-way-right {
  padding: 56px 24px;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  gap: 96px;
  justify-content: center;
}

.subscriber-create-way-right-item {
}

.subscriber-create-way-right-item img {
  margin-bottom: 41px;
  width: 175px;
  height: 175px;
  border-radius: 8px;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.06);
}

.subscriber-create-way-button {
  width: 100%;
  padding: 14px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  cursor: pointer;
}
</style>
